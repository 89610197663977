
.projectButton  {
    border: none;
    color: #666;
    padding: 0px 5px;
    margin-right: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background: linear-gradient(to bottom, rgba(220, 220, 220, 0) 0%, rgba(220, 220, 220, 0) 95%, gold 95%, gold 100%);
}

.projectButton:after {
    background-color: black;
}


.projectButton:hover {
    color: black;
    text-decoration: none;
}

.projectImage {
    min-width: 230px;
    padding: 2%;
    background: linear-gradient(to bottom, 
                                  rgba(220, 220, 220, 0) 0%,
                                  rgba(220, 220, 220, 0) 22%, 
                                  gold 22%, 
                                  gold 100%); 
}


@media (max-width: 768px) {
    .projectButton h4 {
        font-size: 16px;
    }
}

@media (max-width: 510px) {
    .col {
        padding: 2vw !important;
    }
    .projectImage {
        margin-bottom: 8%;
    }

    .projectButton h4 {
        font-size: 14px;
    }
}