.helloButton  {
    border: none;
    color: #666;
    padding: 0px 0px;
    margin-top: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background: linear-gradient(to bottom, rgba(220, 220, 220, 0) 0%, rgba(220, 220, 220, 0) 95%, skyblue 95%, skyblue 100%);
}

.helloButton:after {
    background-color: black;
}


.helloButton:hover {
    color: black;
    text-decoration: none;
}