.navi {
    background-color: var(--off-white);
    position: sticky;
    top: 0px;
    width: 100%;
    height: 65px;
    z-index: 1000;
    -webkit-transform: none;
    transform: none;
    transition: transform 0.2s;
    will-change: transform;
  }

  .navi-hidden {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    transition: transform 0.2s 0.3s;
    
  }
  
  h3.navi {
    cursor:  pointer;
  }
  
  .navi-logo {
    display: inline-block;
    height: 1.2em;
  }

  h5.navi-logo {
    margin: 0px;
  }
  
  .navi-logo:hover {
    cursor: pointer;
    border-bottom: 2px solid skyblue;
  }
  
  
  .navi .navi-content {
    padding: 0rem 3rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  ul.navi-items {
      margin: 0px;
  }
  
  .navi-item {
    display: inline;
    margin-left: 2rem;
    color: #666;
  }
  
  .navi-item:hover {
    color: black;
    cursor: pointer;
  }
  
  .navi-item > .activeGold {
    border-bottom: 2px solid gold;
  
  }
  
  .navi-item > .activePurple {
    border-bottom: 2px solid crimson;
  
  }

  @media (max-width: 750px) {
    .navi .navi-content {
      padding: 0rem 2rem;
    }

    h5.navi-logo {
      font-size: 16px;
    }

    .navi-item {
      font-size: 14px;
    }

    .next-button {
      display: none;
    }
  }

 

  @media (max-width: 480px) {

    .navi-content {
      padding: 0rem 2rem;
    }

    ul.navi-items {
      padding-left: 1em;
    }
    h5.navi-logo {
      font-size: 4vw;
    }

    .navi-item {
      margin-left: 1em;
      font-size: 4vw;
    }
  }

  .hidden {
    display: none !important;
    margin: 0px;
    padding: 0px;
  }

  a:link, a:hover {
    text-decoration: none;
  }