.footer-content {
  position: fixed;
  bottom: 10px;
  left: 10px;
  padding: 0px 20px;
  transition: all .8s;
}

.footer-content-hidden {
  bottom: -100px !important;
}


@media (max-width: 700px) {
    .footer-content {
      font-size: 0.7rem;
      padding: 0px 10px;
      bottom: 0px;
    }
  }