.contactButton  {
    border: none;
    color: #666;
    padding: 0px 0px;
    margin-top: 20px;
    margin-right: 40px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    border-bottom: 2px solid crimson;
    overflow: hidden;
    white-space: nowrap;
}

.contactButton:after {
    background-color: black;
}


.contactButton:hover {
    color: black;
    text-decoration: none;
}

.contactButton h4 {
   font-size: 20px;
}

@media (max-width: 760px)
{
    h1.display-4 {
        font-size: 8vw;
    }
    .contactButton h4 {
        font-size: 16px;
    }
}

@media (max-width: 500px)
{
    .contactButton h4 {
        font-size: 0.7rem;
    }

    h1.display-4 {
        font-size: 8vw;
    }
}