@font-face {
  font-family: "tex-gyre";
  src: url('./fonts/texgyreheros-regular-webfont.woff') format('woff');
}


body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica", "tex-gyre", "Arial",  "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, a, p {
  font-family: "Helvetica", "tex-gyre", "Arial",  "sans-serif";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.carousel-control-prev, .carousel-control-next {
  max-width: 120px;
}

.carousel-control-prev:hover, .carousel-control-next:hover {
  cursor: pointer;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
 
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '}';
  font-size: 55px;
  color: #4c4c47;
}

.carousel-control-prev-icon:after {
  content: '{';
  font-size: 55px;
  color: #4c4c47;
}


.carousel-indicators {
  margin-bottom: 40px;
}

.carousel-indicators li {
  background-color: #4c4c47;
}

@media (max-height: 760px) {
  h1 {
    font-size: 28px;
  }

}

@media (max-width: 800px) {
  .carousel-indicators {
    display: none;
  }
}

@media (max-width: 770px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

}


@media (max-width: 700px) {
  h1 {
    font-size: 21px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  .display-3 {
    font-size: 32px;
  }

  .lead {
    font-size: 1.0rem;
  }
}

@media (max-width: 360px) {
  .lead {
    font-size: 0.8rem;
  }

  a {
  }
}

