:root {
  --off-white: #fafafa;
}

h1 {
  font-family: 'Helvetica Neue', 'Arial';
}
p {
  line-height: 1.5em;
}

h1 > .section {
  margin: 20;
  padding: 20;
}




